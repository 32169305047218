import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BnNgIdleService } from "bn-ng-idle";
import { TierResult, getGPUTier } from "detect-gpu";
import { Howl, Howler } from "howler";
import { DeviceDetectorService } from "ngx-device-detector";
import { BehaviorSubject, Subject } from "rxjs";
import { Reverb, Soundfont } from "smplr";
import { album } from "./util/album";
import { awsBaseApi, bitmidi, defaultDevice, defaultExhibit, gptUrl, openseaCollection, visualizerVideosMinimal } from "./util/constants";
import { getIPFSUrl } from "./util/helpers";
import { instruments } from "./util/instruments";
import { BitMidiResponse, Device, FishInterface, MidiDevice, Spatial } from "./util/types";

declare let navigator: any;
declare let window: any;
declare let documentPictureInPicture: any;

let frame = 0;
let stereoPan = -1;
const positionalX = 0;

@Injectable({
	providedIn: "root",
})
export class ArcadeService {
	fishSelected = new Subject<FishInterface>();
	pagerMessage = new Subject();
	playerMessage = new Subject();
	sceneMessage = new Subject();
	spatial = new Subject<Spatial>();
	videoRef: HTMLVideoElement;
	soundfont: any;
	synthAudioContext: AudioContext;
	player = {
		track: album.tracks[0],
		title: "...",
		volume: 0.3,
		isPlaying: false,
		isMuted: false,
		isInit: false,
		isPIP: false,
		isSpatial: false,
		playlist: undefined,
		viz: "assets/video/dim_vx_80.mp4",
		sprites: "assets/audio/sprite.mp3",
		midiEnabled: false,
		midiInit: false,
		format: "webm",
		backupFormat: "mp3",
		cabinet: false,
		screenSaver: false,
	};
	midiInput: any;
	gpu: TierResult;
	device: Device;
	pipWindow: any;
	sprites: Howl;
	instrument: string;
	reverb: Reverb;
	gamepad: any;
	private currentExhibit = new BehaviorSubject<string>(defaultExhibit); // default-chain
	private exhibitSettings = new BehaviorSubject<any>(null);

	constructor(
		private http: HttpClient,
		private deviceService: DeviceDetectorService,
		private idler: BnNgIdleService,
		private router: Router,
	) {
		this.keyboardEvents();

		this.animate = this.animate.bind(this);
		this.device = defaultDevice;
		this.playerMessage.subscribe((event) => {
			// Analytics.record(event.name, event.attributes);
			// if (typeof event === "string") {
			// 	Analytics.record({ name: event });
			// }
			if (event === "track-onload") {
				this.transformVideo();
			}
		});

		this.fishSelected.subscribe((fish: FishInterface) => {
			// if (fish["3d"] starts with ipfs:// use helper function to get url
			if (fish["3d"] && fish["3d"].startsWith("ipfs://")) {
				const url = getIPFSUrl(fish["3d"]);

				fish["3d"] = url;
			}

			if (fish.attributes) {
				const hasVoice = fish.attributes.find((trait) => trait.trait_type === "voice");

				if (hasVoice) {
					// play the instrument of the fish on selection
					// this.playInstrumentWithString(hasVoice.value as (typeof instruments)[0]);
				}
			}
		});

		this.getDevice();
		this.gpuDetective();

		this.loadIdler();
		this.loadExhibitSettings("ethereum");
	}

	// Signal Experiment
	handlePage(signal: string) {}

	sendPage(signal: string) {
		// if (this.pager) {
		// 	this.pager.beep(signal);
		//
		// }
	}

	getDevice() {
		this.deviceDetector();
		// this.gpuDetective();

		if (window.matchMedia("(display-mode: standalone)").matches) {
			this.device.isStandalone = true;
		} else if (window.standalone || this.device.deviceInfo.userAgent.indexOf("Electron") > -1) {
			this.device.isStandalone = true;
		}

		if (window.screen && window.screen.orientation && window.screen.orientation.type) {
			this.device.orientation = window.screen.orientation.type;
		} else if (window.orientation && window.orientation !== undefined) {
			if (window.orientation === 0) {
				this.device.orientation = "portrait-primary";
			} else if (window.orientation === 90) {
				this.device.orientation = "landscape-primary";
			} else if (window.orientation === -90) {
				this.device.orientation = "landscape-secondary";
			} else if (window!.orientation) {
				this.device.orientation = "portrait-secondary";
			}
		} else if (this.device.deviceInfo.orientation === "portrait" && window.orientation !== 0) {
			this.device.orientation = "portrait-primary";
		} else {
			this.device.orientation = "unknown";
		}

		if (document.visibilityState === "visible") {
			this.device.isTabVisible = true;
		} else if (document.visibilityState === "hidden") {
			this.device.isTabVisible = false;
		} else if (document.visibilityState === "prerender") {
			this.device.isTabVisible = false;
		} else if (document.visibilityState === "unloaded") {
			this.device.isTabVisible = false;
		}

		this.device.hasSpatialAudio = Howler.codecs("flac");

		this.device.isDesktop = this.device.deviceInfo.deviceType === "desktop";

		if (navigator.gpu !== undefined) {
			this.device.hasWebGPU = true;
		}

		if (this.device.isDesktop) {
			this.audioDetective();
		}

		if (window.innerWidth < 431) {
			this.device.isMobile = true;
		}

		if ("documentPictureInPicture" in window) {
			this.device.hasDocumentPIP = true;
		}

		if (navigator.gamepads && navigator.gamepads[0]) {
			this.device.hasStick = true;
		}

		if (this.device.isMobile) {
			let lastTouchEnd = 0;
			document.addEventListener(
				"touchstart",
				function (event) {
					if (event.touches.length > 1) {
						event.preventDefault();
					}
				},
				{ passive: false },
			);

			document.addEventListener(
				"touchend",
				function (event) {
					const now = new Date().getTime();
					if (now - lastTouchEnd <= 300) {
						event.preventDefault();
					}
					lastTouchEnd = now;
				},
				false,
			);
		}

		return this.device;
	}

	audioDetective() {
		this.device.hasSpatialAudio = Howler.codecs("flac");
	}

	async gpuDetective(glContext?: any): Promise<TierResult | undefined> {
		let gpuTier;
		const benchmarksURL = "./assets/benchmarks";
		try {
			if (glContext) {
				gpuTier = await getGPUTier({ benchmarksURL: benchmarksURL, glContext: glContext });
				this.gpu = gpuTier;
			} else {
				gpuTier = await getGPUTier({
					benchmarksURL: benchmarksURL,
				});
				this.gpu = gpuTier;
			}

			if (this.gpu.type === "FALLBACK") {
				this.gpu.tier = 3;
			}

			// if (!this.gpu.device) {
			// 	// this.gpu = await getGPUTier();
			// }

			this.device.gpu = this.gpu;
			return this.device.gpu;
		} catch (error) {
			console.error(error);
			// throw error;
			return;
		}
	}

	airplayDetective() {
		// @ts-ignore
		if (window.WebKitPlaybackTargetAvailabilityEvent) {
			const video = document.getElementById("videoPlayer");
			video?.addEventListener("webkitplaybacktargetavailabilitychanged", function (event) {
				// switch (event.availability) {
				//   case "available":
				//     airPlayButton.hidden = false;
				//     airPlayButton.disabled = false;
				//     break;
				//   case "not-available":
				//     airPlayButton.hidden = true;
				//     airPlayButton.disabled = true;
				//     break;
				// }
				// });
			});
		}
	}

	castDetective() {
		// @ts-ignore
		if (chrome.cast && chrome.cast.isAvailable) {
			this.device.hasCast = true;
		}
	}

	deviceDetector() {
		// let standalone = false;
		// if (window.standalone || window.matchMedia("(display-mode: standalone)").matches) {
		// 	standalone = true;
		// }
		this.device.isMobile = this.deviceService.isMobile();
		this.device.isTablet = this.deviceService.isTablet();
		this.device.isDesktop = this.deviceService.isDesktop();
		this.device.deviceInfo = this.deviceService.getDeviceInfo();
		return this.device.deviceInfo;
	}

	selectedFish() {
		return this.fishSelected;
	}

	playerEvent() {
		return this.playerMessage;
	}

	pagerEvent() {
		return this.pagerMessage;
	}

	sceneEvent() {
		return this.sceneMessage;
	}

	transformVideo() {
		if (!this.videoRef) {
			this.videoRef = document.getElementById("videoPlayer") as HTMLVideoElement;
			if (!this.videoRef) {
				return;
			}
		}

		const p = visualizerVideosMinimal.src;

		const poster = visualizerVideosMinimal.poster;
		const newFileName = this.player.track.id + ".mp4";
		const newPath = p.replace(/[^/]*$/, newFileName);
		const posterFilename = this.player.track.id + "-poster.jpg";
		const posterPath = poster.replace(/[^/]*$/, posterFilename);

		this.videoRef.src = newPath;
		this.videoRef.poster = posterPath;
		// const geometry = new SphereGeometry(500, 60, 40);
		// geometry.scale(-1, 1, 1);

		// // Create a VideoTexture from the video element
		// const videoTexture = new VideoTexture(this.videoRef);
		// videoTexture.minFilter = LinearFilter;
		// videoTexture.magFilter = LinearFilter;
		// videoTexture.format = RGBFormat;

		// // Create a MeshBasicMaterial with the VideoTexture
		// const material = new MeshBasicMaterial({ map: videoTexture });

		// // Create a Mesh with the sphere geometry and material, then add it to the scene
		// const mesh = new Mesh(geometry, material);
		// this.scene.add(mesh);

		return;
	}

	// 1st Function ======= 1st Function ======= 1st Function ======= 1st Function
	async getRandomMidi(): Promise<string | null> {
		try {
			const data = await this.http.get<BitMidiResponse>(awsBaseApi + "/midi/random").toPromise();
			const fullUrl: string = bitmidi.baseUrl + "/" + data?.result.result.downloadUrl;
			return fullUrl;
		} catch (error) {
			console.error(error);
			return null;
		}
	}
	// DONT DELETE ======= DONT DELETE ======= DONT DELETE ======= DONT DELETE

	// ============ MIDI ==============
	supportsMidi() {
		return typeof window !== "undefined" && typeof window.navigator !== "undefined" && navigator.requestMIDIAccess !== undefined;
	}

	flashMessageonDisplay(message: string, duration = 1000) {}

	// Entrypoint called from app. refactor to this service
	async isMidiInit(): Promise<MidiDevice | boolean> {
		// detect if browser supports WebMIDI
		if (!navigator.requestMIDIAccess) {
			// this.flashMessageonDisplay("WebMIDI is not supported in this browser");
			return false;
		}

		return navigator.requestMIDIAccess().then(
			async (midiAccess) => {
				const midi = midiAccess;
				const inputs = midiAccess.inputs;
				let response;

				if (inputs.size === 0) {
					this.flashMessageonDisplay("MIDI Not Found");
					return false;
				} else {
					// Iterate over the list of inputs and add an event listener to each one
					inputs.forEach(async (input) => {
						this.midiInput = input;
						this.flashMessageonDisplay(input.name + " " + input.type + " Connected");
						response = {
							name: input.name,
							type: input.type,
							manufacturer: input.manufacturer,
							state: input.state,
							version: input.version,
							id: input.id,
						};
						input.addEventListener("midimessage", (event) => {
							const midiMessage = event.data;
							const { message, messageType, data1, data2 } = this.translateMIDIMessage(midiMessage);
							this.flashMessageonDisplay(message, 0);
							this.playerEvent().next("midi-message");
							const note = { messageType, data1, data2 };
							this.playSynth(note);
						});
						await this.initSoundfont();

						// this.soundfont.listenToMidi(input);
					});
					this.player.midiInit = true;
					return response;
				}
			},
			(error) => {
				this.flashMessageonDisplay("MIDI error");
				console.error(error);
				return false;
			},
		);
	}

	async initSoundfont(): Promise<boolean> {
		if (!this.synthAudioContext) {
			this.synthAudioContext = new AudioContext();
		}

		this.setMidiInstrument("applause");
		return true;
	}

	// Translate a MIDI message into a human-readable string
	translateMIDIMessage(data) {
		// Split the status byte and data bytes
		const status = data[0];
		const data1 = data[1];
		const data2 = data[2];

		// Extract the channel from the status byte
		const channel = status & 0xf;

		// Extract the type of message from the status byte
		const messageType = status >> 4;

		// Initialize the human-readable message
		let message = "channel_" + channel + " ";

		if (messageType == 0x8) {
			message += "note " + data1 + " off with velocity " + data2;
		} else if (messageType == 0x9) {
			message += "note " + data1 + " on with velocity " + data2;
		} else if (messageType == 0xa) {
			message += "Polyphonic key " + data1 + " pressure " + data2;
		} else if (messageType == 0xb) {
			message += "control " + data1 + " with value " + data2;
		} else if (messageType == 0xc) {
			message += "program " + data1 + " with value " + data2;
		} else if (messageType == 0xd) {
			message += "pressure " + data1;
		} else if (messageType == 0xe) {
			// calculate the 14-bit pitch bend value
			const pitchBend = (data2 << 7) | data1;
			message += data1 + " " + data2 + " " + "pitchbend " + pitchBend;
		} else {
			message += "Unknown message type";
		}

		return { message, messageType, data1, data2 };
	}

	async toggleMidi() {
		if (!this.player.midiInit) {
			await this.isMidiInit();
		}

		if (this.player.midiEnabled) {
			this.player.midiEnabled = false;
			this.flashMessageonDisplay("MIDI Disabled");
		} else {
			this.player.midiEnabled = true;
			this.flashMessageonDisplay("MIDI Enabled");
		}

		return this.player.midiEnabled;
	}

	disconnectMidi() {
		// navigator.midi;
	}

	getMidiInstruments() {
		return instruments;
	}

	getMidiInstrument() {
		return this.soundfont;
	}

	setReverb(_reverb) {
		this.soundfont.output.sendEffect("reverb", _reverb);
	}

	async setMidiInstrument(_instrument) {
		if (!this.synthAudioContext) return;
		return new Soundfont(this.synthAudioContext, { instrument: _instrument }).loaded().then((soundfont) => {
			this.soundfont = soundfont;
			this.instrument = _instrument;
			this.reverb = new Reverb(this.synthAudioContext);
			this.soundfont.output.addEffect("reverb", this.reverb, 0.5);
			this.playerEvent().next("voice:loaded");
		});
		// this.pagerEvent().next("><(((o> voice_changed " + _instrument);
	}

	async playInstrumentWithString(_instrument: (typeof instruments)[0]) {
		if (!this.synthAudioContext) {
			this.synthAudioContext = new AudioContext();
		}

		if (_instrument !== this.instrument) {
			await this.setMidiInstrument(_instrument);
			this.soundfont.start({ note: "C4" });
		} else {
			this.soundfont.start({ note: "C6" });
		}
	}

	playSynth(_note) {
		if (!this.synthAudioContext || !this.soundfont) this.initSoundfont();
		if (_note.messageType === 9) {
			this.soundfont.start({ note: _note.data1, velocity: _note.data2 });
		} else if (_note.messageType === 8) {
			this.soundfont.stop({ note: _note.data1 });
		}
	}

	playSynthNoteFromString(_note: string) {
		this.soundfont.start({ note: _note });
	}

	// ============ KEYBOARD ============

	keyboardEvents() {
		const escapeKeyCode = 27;
		// document.addEventListener("mousemove", onMouseMove, false);
		// document.addEventListener("keydown", onKeyDown, false);
		// document.addEventListener("keyup", onKeyUp, false);

		window.addEventListener("keydown", (event) => {
			if (event.keyCode === escapeKeyCode) {
				this.sceneMessage.next("escape");
				// this.initMidi();
				// this.playerEvent().next("full");
			}

			// Spacebar
			if (event.keyCode === 32) {
				// play/pause

				this.playerEvent().next("spacebar");
			}

			// N key
			if (event.keyCode === 78) {
				// next track
				this.playerEvent().next("KeyN");
			}

			if (event.key === "ArrowLeft") {
				this.playerMessage.next("key-left");
			} else if (event.key === "ArrowRight") {
				this.playerMessage.next("key-right");
			}
		});

		window.addEventListener("keyup", (event) => {
			if (event.keyCode === escapeKeyCode) {
				// this.sceneMessage.next("escape");
				// this.playerEvent().next("full");
			}
		});
	}

	bluetoothDetective() {
		const filters = [{ name: "Nimbus" }];
		navigator.bluetooth
			.requestDevice({ filters: filters })
			.then((device) => {})
			.catch((error) => {});
	}

	initGamepad() {
		// Detect if the gamepad is connected and select first available
		navigator.getGamepads().forEach((pad) => {
			if (pad) {
				this.gamepad = pad;
				this.device.hasStick = true;
				this.playerEvent().next("gamepad:connected");
				// break loop
				return;
			}
		});
	}

	gamepadEvents() {
		// Check if the Web Bluetooth API is supported
		if (navigator.bluetooth) {
			// Request permission to access the gamepad
			navigator.bluetooth
				.requestDevice({ filters: [{ services: ["gamepad"] }] })
				.then((device: any) => {
					// Connect to the gamepad
					return device.gatt.connect();
				})
				.then((gatt) => {
					// Get the gamepad's GATT service
					return gatt.getPrimaryService("gamepad");
				})
				.then((service) => {
					// Get the button press characteristic of the gamepad
					return service.getCharacteristic("button_press");
				})
				.then((characteristic) => {
					// Subscribe to the button press characteristic
					characteristic.startNotifications().then((char) => {
						char.addEventListener("characteristicvaluechanged", (event) => {
							// Handle the button press event here
						});
					});
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			console.error("Web Bluetooth API is not supported by this browser");
		}

		// const gamepad = navigator.getGamepads()[0];
		//
	}

	mouseEvents() {
		const onMouseMove = (event) => {
			//
			// this.playerMessage.next(event);
		};

		const onMouseDown = (event) => {
			//
			// this.playerMessage.next(event);
		};

		const onMouseUp = (event) => {
			//
			// this.playerMessage.next(event);
		};

		document.addEventListener("mousemove", onMouseMove, false);
		document.addEventListener("mousedown", onMouseDown, false);
		document.addEventListener("mouseup", onMouseUp, false);
	}

	spatialEvents() {
		return this.spatial;
	}

	setSpatialAudio(_player: Spatial) {
		if (_player.type === "stereo") {
			Howler.stereo(_player.stereo.pan);
		} else if (_player.type === "stereo-wave") {
			stereoPan = Math.cos(frame / 100);
			if (!_player.init) {
				_player.init = true;
				this.animate();
			}

			Howler.stereo(stereoPan);
		} else if (_player.type === "positional") {
			Howler.pos(_player.pos.x, _player.pos.y, _player.pos.z);
		} else if (_player.type === "orientation") {
			Howler.orientation(_player.orientation.x, _player.orientation.y, _player.orientation.z, _player.orientationUp.x, _player.orientationUp.y, _player.orientationUp.z);
		} else if (_player.type === "doom") {
			// Howler.pannerAttr({
			//   panningModel: "HRTF",
			//   refDistance: 0.8,
			//   rolloffFactor: 2.5,
			//   distanceModel: "exponential",
			// });
		}

		Howler.volume(_player.volume);
		this.player.isSpatial = true;
	}

	setStereoWave() {
		this.setSpatialAudio({
			type: "stereo-wave",
			pos: { x: positionalX, y: 0, z: 0 },
			orientation: { x: 0, y: 0, z: 0 },
			orientationUp: { x: 0, y: 0, z: 0 },
			volume: 1,
			stereo: {
				pan: 0,
				id: undefined,
			},
		});
	}

	// Use requestAnimationFrame to update the values on each frame
	private animate() {
		if (this.player.isSpatial) {
			frame++;
			this.setSpatialAudio({
				type: "stereo-wave",
				pos: { x: positionalX, y: 0, z: 0 },
				orientation: { x: 0, y: 0, z: 0 },
				orientationUp: { x: 0, y: 0, z: 0 },
				volume: 1,
				stereo: {
					pan: 0,
					id: undefined,
				},
				init: true,
			});

			requestAnimationFrame(this.animate);
		}
	}

	stopStereoWave() {
		this.setSpatialAudio({ type: "stereo", stereo: { pan: 0, id: undefined } } as Spatial);
		this.player.isSpatial = false;
	}

	loadIdler() {
		this.idler.startWatching(60).subscribe((isTimedOut: boolean) => {
			if (isTimedOut) {
				this.toggleScreenSaver();
			}
		});
	}

	toggleScreenSaver() {
		this.player.screenSaver = !this.player.screenSaver;
	}

	async togglePictureInPicture() {
		const pip = document.pictureInPictureElement;

		if (this.player.isPIP) {
			this.pipWindow.close();
			return;
		}

		const pipElement = document.getElementById("fishtank");

		documentPictureInPicture.onenter = (event) => {
			this.player.isPIP = true;

			this.playerMessage.next("pip:enter");
		};

		// @ts-ignore
		this.pipWindow = await documentPictureInPicture.requestWindow({
			initialAspectRatio: 4 / 3,
			copyStyleSheets: true,
			width: 640,
			height: 480,
		});

		// Move the player to the Picture-in-Picture window.
		this.pipWindow.document.body.append(pipElement);
		this.pipWindow.document.title = "Picture-in-Picture Metaquarium";

		this.pipWindow.addEventListener("unload", (event) => {
			this.player.isPIP = false;
			const playerContainer = document.querySelector("#scene") as HTMLElement;
			const pipPlayer = event.target.querySelector("#fishtank");
			playerContainer.append(pipPlayer);
			this.playerMessage.next("pip:exit");
		});

		this.pipWindow.addEventListener("resize", (event) => {
			this.playerMessage.next("pip:resize");
		});
	}

	initWebGPU() {
		if (window.GPUShaderStage === undefined) {
			window.GPUShaderStage = { VERTEX: 1, FRAGMENT: 2, COMPUTE: 4 };
		}
	}

	loadSprites() {
		this.sprites = new Howl({
			src: ["assets/audio/tankSprites.mp3"],
			volume: 0.1,
			sprite: {
				bell_ripple: [0, 2000],
				bounce_alert: [3000, 4000],
				calm_alert: [8000, 2000],
				calypso_alt: [11000, 4000],
				calypso: [16000, 4000],
				computer_toggle: [21000, 1714.2857142857154],
				deep_alert: [24000, 4000],
				edm_alert: [29000, 4000],
				fairy_ring: [34000, 4000],
				incomming_echo_alt: [39000, 4000],
				small_chime: [44000, 2000],
			},

			onload: () => {},
		});
	}

	eject() {
		window.open("https://metaquarium.xyz", "_self");
	}

	shop() {
		window.open(openseaCollection, "_self");
	}

	chatGpt() {
		window.open(gptUrl, "_self");
	}

	wasd() {
		this.playerMessage.next("wasd");
		this.device.isWasd = !this.device.isWasd;
	}

	// Exhibit management methods
	getCurrentExhibit() {
		return this.currentExhibit.asObservable();
	}

	async setExhibit(exhibit: string) {
		// First unload current exhibit if exists
		if (this.currentExhibit.value) {
			this.playerEvent().next(`unload-${this.currentExhibit.value}`);
		}

		// Load new exhibit settings
		await this.loadExhibitSettings(exhibit);

		// Update current exhibit
		this.currentExhibit.next(exhibit);

		// Notify about exhibit change with settings
		this.playerEvent().next(`exhibit-${exhibit}`);
	}

	private async loadExhibitSettings(exhibit: string) {
		try {
			const settings = await this.http.get(`assets/exhibits/${exhibit}Settings.json`).toPromise();
			// Get the first preset's settings (usually "1")
			if (settings) {
				const presetSettings = settings[Object.keys(settings)[0]];
				this.exhibitSettings.next(presetSettings);
			}
		} catch (error) {
			console.error(`Failed to load settings for exhibit: ${exhibit}`, error);
		}
	}

	getExhibitSettings() {
		return this.exhibitSettings.asObservable();
	}
}

import { albumS3Url } from "./constants";
import { Album } from "./types";

// Each track has a scene configuration
// Each track has a breed configuration
// Each breed configuration has a fish type and population

export const album: Album = {
	name: "Ethereum",
	exhibit: "ethereum",
	tracks: [
		{
			id: 1,
			title: "00_ζo))))彡.wav",
			slug: "ethereum-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
						},
					},
					breeds: [
						{ shark: 9 },
						{ seahorse: 5 },
						{ crab: 5 },
						{ betafish: 0 },
						{ dori: 1 },
						{ seaturtle: 5 },
						{ glowfish: 20 },
						{ angelfish: 10 },
						{ babyfish: 3 },
						{ blowfish: 7 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/8.mp3",
			webm: albumS3Url + "web/8.webm",
			flac: albumS3Url + "hifi/8.flac",
			howl: null,
			schoolSize: 3,
			schools: 5,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: -Math.PI / 3,
				z: 0.0,
			},
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
				movement: {
					x: 0.1,
					y: 0.0,
					z: 0,
				},
			},
		},
		{
			id: 2,
			title: "01_ʚʘ͡))彡.wav",
			slug: "ethereum-2",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: Math.PI / 4,
							z: 0,
						},
						curve: "spiral",
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
						},
					},
					breeds: [
						{ shark: 4 },
						{ seahorse: 30 },
						{ crab: 15 },
						{ betafish: 0 },
						{ dori: 1 },
						{ seaturtle: 0 },
						{ glowfish: 30 },
						{ angelfish: 15 },
						{ babyfish: 1 },
						{ blowfish: 2 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/4.mp3",
			webm: albumS3Url + "web/4.webm",
			flac: albumS3Url + "hifi/4.flac",
			howl: null,
			schoolSize: 4,
			schools: 5,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
				movement: {
					x: 0.01,
					y: 0.0,
					z: 0.0,
				},
			},
		},
		{
			id: 3,
			title: "02_(/)!_!(/).wav",
			slug: "ethereum-3",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
						},
					},
					breeds: [
						{ shark: 2 },
						{ seahorse: 2 },
						{ crab: 10 },
						{ betafish: 0 },
						{ dori: 14 },
						{ seaturtle: 0 },
						{ glowfish: 30 },
						{ angelfish: 25 },
						{ babyfish: 1 },
						{ blowfish: 31 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/5.mp3",
			webm: albumS3Url + "web/5.webm",
			flac: albumS3Url + "hifi/5.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.25,
					y: 0.15,
					z: 0.25,
				},
				movement: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
			},
		},
		{
			id: 4,
			title: "03_ε( 'Θ' )϶.wav",
			slug: "ethereum-4",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 7 },
						{ seahorse: 20 },
						{ crab: 0 },
						{ betafish: 0 },
						{ dori: 20 },
						{ seaturtle: 40 },
						{ glowfish: 20 },
						{ angelfish: 5 },
						{ babyfish: 9 },
						{ blowfish: 0 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/6.mp3",
			webm: albumS3Url + "web/6.webm",
			flac: albumS3Url + "hifi/6.flac",
			howl: null,
			schoolSize: 4,
			schools: 9,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.15,
					y: 0.15,
					z: 0.15,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
		},
		{
			id: 5,
			title: "04⋙l;((((*≿.wav",
			slug: "ethereum-5",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 1 },
						{ seahorse: 1 },
						{ crab: 5 },
						{ betafish: 0 },
						{ dori: 12 },
						{ seaturtle: 8 },
						{ glowfish: 30 },
						{ angelfish: 5 },
						{ babyfish: 10 },
						{ blowfish: 13 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/7.mp3",
			webm: albumS3Url + "web/7.webm",
			flac: albumS3Url + "hifi/7.flac",
			howl: null,
			schoolSize: 10,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
		},
		{
			id: 6,
			title: "05_くコ∷彡.wav",
			slug: "ethereum-6",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 1 },
						{ seahorse: 0 },
						{ crab: 35 },
						{ betafish: 0 },
						{ dori: 25 },
						{ seaturtle: 0 },
						{ glowfish: 40 },
						{ angelfish: 25 },
						{ babyfish: 1 },
						{ blowfish: 0 },
						{ hackerfish: 0 },
					],
				},
			},
			mp3: albumS3Url + "web/2.mp3",
			webm: albumS3Url + "web/2.webm",
			flac: albumS3Url + "hifi/2.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.01,
					y: 0.01,
					z: 0.01,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
		},
		{
			id: 7,
			title: "06_≼Θ)))))⋊.wav",
			slug: "ethereum-7",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 8 },
						{ seahorse: 15 },
						{ crab: 5 },
						{ betafish: 0 },
						{ dori: 1 },
						{ seaturtle: 10 },
						{ glowfish: 10 },
						{ angelfish: 3 },
						{ babyfish: 10 },
						{ blowfish: 30 },
						{ hackerfish: 20 },
					],
				},
			},
			mp3: albumS3Url + "web/1.mp3",
			webm: albumS3Url + "web/1.webm",
			flac: albumS3Url + "hifi/1.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.09,
					y: 0.05,
					z: 0.07,
				},
				movement: {
					x: 0.01,
					y: 0.01,
					z: 0.01,
				},
			},
		},
		{
			id: 8,
			title: "07_{;☄` ̧ ̧. ́ ̄`·.wav",
			slug: "ethereum-8",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 4 },
						{ seahorse: 2 },
						{ crab: 45 },
						{ betafish: 0 },
						{ dori: 12 },
						{ seaturtle: 0 },
						{ glowfish: 10 },
						{ angelfish: 5 },
						{ babyfish: 50 },
						{ blowfish: 30 },
						{ hackerfish: 3 },
					],
				},
			},
			mp3: albumS3Url + "web/3.mp3",
			webm: albumS3Url + "web/3.webm",
			flac: albumS3Url + "hifi/3.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
			gravity: true,
		},
	],
};

export const dogeAlbum: Album = {
	name: "Doge",
	exhibit: "dogecoin",
	tracks: [
		{
			id: 1,
			title: "Much Swim",
			slug: "doge-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 1, g: 1.0, b: 0.8 },
							opacity: 0.6,
						},
					},
					breeds: [{ "doge-sprite": 90 }, { glowfish: 40 }, { babyfish: 30 }],
				},
			},
			mp3: albumS3Url + "web/1.mp3",
			webm: albumS3Url + "web/1.webm",
			flac: albumS3Url + "hifi/1.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: -Math.PI / 3,
				z: 0.0,
			},
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Very Bubble",
			slug: "doge-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.3, g: 0.5, b: 0.9 },
							opacity: 0.7,
						},
					},
					breeds: [{ "doge-sprite": 65 }, { glowfish: 15 }, { babyfish: 20 }],
				},
			},
			mp3: albumS3Url + "web/2.mp3",
			webm: albumS3Url + "web/2.webm",
			flac: albumS3Url + "hifi/2.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: Math.PI / 4,
				z: 0.0,
			},
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "Wow Ocean",
			slug: "doge-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.1, g: 0.3, b: 0.7 },
							opacity: 0.65,
						},
					},
					breeds: [{ "doge-sprite": 95 }, { glowfish: 20 }, { babyfish: 25 }],
				},
			},
			mp3: albumS3Url + "web/3.mp3",
			webm: albumS3Url + "web/3.webm",
			flac: albumS3Url + "hifi/3.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: 0.0,
				z: 0.0,
			},
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false,
		},
		{
			id: 4,
			title: "Such Float",
			slug: "doge-4",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 130, z: -220 },
						rotation: { x: 0, y: Math.PI / 5, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.2, g: 0.5, b: 0.8 },
							opacity: 0.7,
						},
					},
					breeds: [{ "doge-sprite": 70 }, { glowfish: 25 }, { babyfish: 30 }],
				},
			},
			mp3: albumS3Url + "web/4.mp3",
			webm: albumS3Url + "web/4.webm",
			flac: albumS3Url + "hifi/4.flac",
			howl: null,
			schoolSize: 5,
			schools: 8,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: Math.PI / 3,
				z: 0.0,
			},
			animate: {
				oscilate: { x: 0.07, y: 0.07, z: 0.07 },
				movement: { x: 0.08, y: 0.06, z: 0.06 },
			},
			gravity: false,
		},
	],
};

export const bitcoinAlbum: Album = {
	name: "Bitcoin",
	exhibit: "bitcoin",
	tracks: [
		{
			id: 1,
			title: "Satoshi's Vision",
			slug: "bitcoin-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 1, g: 0.8, b: 0.2 },
							opacity: 0.6,
						},
					},
					breeds: [{ shark: 3 }, { seahorse: 15 }, { glowfish: 25 }, { angelfish: 20 }, { babyfish: 10 }, { blowfish: 8 }, { seaturtle: 5 }],
				},
			},
			mp3: albumS3Url + "web/1.mp3",
			webm: albumS3Url + "web/1.webm",
			flac: albumS3Url + "hifi/1.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Digital Gold",
			slug: "bitcoin-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.9, g: 0.7, b: 0.1 },
							opacity: 0.7,
						},
					},
					breeds: [{ seahorse: 25 }, { babyfish: 30 }, { dori: 15 }, { blowfish: 10 }],
				},
			},
			mp3: albumS3Url + "web/2.mp3",
			webm: albumS3Url + "web/2.webm",
			flac: albumS3Url + "hifi/2.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: { x: 0.0, y: Math.PI / 4, z: 0.0 },
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "Blockchain Dreams",
			slug: "bitcoin-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 1.0, g: 0.6, b: 0.1 },
							opacity: 0.65,
						},
					},
					breeds: [{ betafish: 20 }, { glowfish: 20 }, { angelfish: 15 }, { crab: 8 }],
				},
			},
			mp3: albumS3Url + "web/3.mp3",
			webm: albumS3Url + "web/3.webm",
			flac: albumS3Url + "hifi/3.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: { x: 0.0, y: 0.0, z: 0.0 },
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false,
		},
	],
};

export const solanaAlbum: Album = {
	name: "Solana",
	exhibit: "solana",
	tracks: [
		{
			id: 1,
			title: "Speed of Light",
			slug: "solana-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.4, g: 0.2, b: 0.8 },
							opacity: 0.6,
						},
					},
					breeds: [{ betafish: 35 }, { glowfish: 25 }, { dori: 10 }, { babyfish: 15 }, { seaturtle: 3 }],
				},
			},
			mp3: albumS3Url + "web/1.mp3",
			webm: albumS3Url + "web/1.webm",
			flac: albumS3Url + "hifi/1.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Fast Transactions",
			slug: "solana-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.3, g: 0.1, b: 0.9 },
							opacity: 0.7,
						},
					},
					breeds: [{ seahorse: 30 }, { angelfish: 35 }, { babyfish: 15 }],
				},
			},
			mp3: albumS3Url + "web/2.mp3",
			webm: albumS3Url + "web/2.webm",
			flac: albumS3Url + "hifi/2.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: { x: 0.0, y: Math.PI / 4, z: 0.0 },
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "Proof of History",
			slug: "solana-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.5, g: 0.2, b: 0.7 },
							opacity: 0.65,
						},
					},
					breeds: [{ glowfish: 40 }, { seaturtle: 8 }, { blowfish: 12 }],
				},
			},
			mp3: albumS3Url + "web/3.mp3",
			webm: albumS3Url + "web/3.webm",
			flac: albumS3Url + "hifi/3.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: { x: 0.0, y: 0.0, z: 0.0 },
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false,
		},
	],
};

export const polygonAlbum: Album = {
	name: "Polygon",
	exhibit: "polygon",
	tracks: [
		{
			id: 1,
			title: "Layer 2 Dreams",
			slug: "polygon-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.6, g: 0.2, b: 0.8 },
							opacity: 0.6,
						},
					},
					breeds: [{ glowfish: 35 }, { angelfish: 25 }, { seahorse: 20 }, { babyfish: 15 }, { dori: 10 }, { blowfish: 8 }, { crab: 5 }],
				},
			},
			mp3: albumS3Url + "web/1.mp3",
			webm: albumS3Url + "web/1.webm",
			flac: albumS3Url + "hifi/1.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Scaling Solutions",
			slug: "polygon-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.7, g: 0.2, b: 0.9 },
							opacity: 0.7,
						},
					},
					breeds: [{ babyfish: 30 }, { dori: 20 }, { blowfish: 15 }],
				},
			},
			mp3: albumS3Url + "web/2.mp3",
			webm: albumS3Url + "web/2.webm",
			flac: albumS3Url + "hifi/2.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: { x: 0.0, y: Math.PI / 4, z: 0.0 },
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "MATIC Magic",
			slug: "polygon-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.8, g: 0.3, b: 0.9 },
							opacity: 0.65,
						},
					},
					breeds: [{ betafish: 25 }, { glowfish: 30 }, { seaturtle: 10 }],
				},
			},
			mp3: albumS3Url + "web/3.mp3",
			webm: albumS3Url + "web/3.webm",
			flac: albumS3Url + "hifi/3.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: { x: 0.0, y: 0.0, z: 0.0 },
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false,
		},
	],
};

export const baseAlbum: Album = {
	name: "Base",
	exhibit: "base",
	tracks: [
		{
			id: 1,
			title: "Coinbase Dreams",
			slug: "base-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.0, g: 0.4, b: 0.9 },
							opacity: 0.6,
						},
					},
					breeds: [{ seahorse: 35 }, { angelfish: 25 }, { babyfish: 20 }],
				},
			},
			mp3: albumS3Url + "web/1.mp3",
			webm: albumS3Url + "web/1.webm",
			flac: albumS3Url + "hifi/1.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Layer 2 Vibes",
			slug: "base-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.1, g: 0.5, b: 1.0 },
							opacity: 0.7,
						},
					},
					breeds: [{ betafish: 30 }, { glowfish: 25 }, { dori: 15 }, { angelfish: 15 }, { seahorse: 12 }, { babyfish: 10 }, { seaturtle: 5 }],
				},
			},
			mp3: albumS3Url + "web/2.mp3",
			webm: albumS3Url + "web/2.webm",
			flac: albumS3Url + "hifi/2.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: { x: 0.0, y: Math.PI / 4, z: 0.0 },
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "Optimistic Waves",
			slug: "base-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.2, g: 0.3, b: 0.8 },
							opacity: 0.65,
						},
					},
					breeds: [{ seaturtle: 12 }, { glowfish: 25 }, { angelfish: 20 }],
				},
			},
			mp3: albumS3Url + "web/3.mp3",
			webm: albumS3Url + "web/3.webm",
			flac: albumS3Url + "hifi/3.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: { x: 0.0, y: 0.0, z: 0.0 },
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false,
		},
	],
};
